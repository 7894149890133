<div>
  <div>
    <span><h1>{{ title }}</h1></span>
  </div>
  <div>
    <mat-label *ngIf="showUitleg"
      [innerHtml]="uitlegText">
    </mat-label>
      <span><button *ngIf="showButton" mat-button (click)="start()">{{buttonText}}</button></span>
  </div>
  <div>
      <mat-label *ngIf="showGetal">{{randomGetal}}</mat-label>
      <div class="inputGetal">
        <input matInput *ngIf="showInput" enabled="true" type="number" [(ngModel)]="invoerGetal" (keyup.enter)="onInputGetal()">
      </div>
  </div>
</div>
